@use "../extension/mixin" as mi;

.l-header {
  @include mi.mq(sp){
    height: 80px;
  }

  @include mi.mq(){
    height: calc(80px + 54px);
  }

  @include mi.mq(){
    &[data-gnav-status="true"] {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      min-width: calc((1024 + (20 * 2)) * 1px);
    }
  }
}