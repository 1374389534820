@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-kv {
  &.-home {
    @include mi.mq(){
      margin-bottom: 24px;
      padding-top: 24px;
      background-image: linear-gradient(to bottom, rgba(var.$manda_green, 60%) 0%,rgba(var.$manda_green, 60%) calc(100% - 72px),rgba(var.$manda_green, 60%) calc(100% - 72px),transparent calc(100% - 72px),transparent 100%);
      text-align: center;
    }

    @include mi.mq(sp){
      margin-right: -24px;
      margin-left: -24px;
      margin-bottom: 16px;
    }

    img {
      @include mi.mq(sp){
        width: 100%;
      }
    }
  }

  &.-category {
    @include mi.mq(){
      display: flex;
      justify-content: center;
    }

    @include mi.mq(sp){
      margin-right: -24px;
      margin-left: -24px;
    }

    .c-container {
      @include mi.mq(){
        min-width: 1024px;
      }
    }
  }
}