@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

@include mi.mq(){
  ._sp {
    display: none !important;
  }
}

@include mi.mq(sp){
  ._pc {
    display: none !important;
  }
}