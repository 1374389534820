@use "../extension/variables" as var;
@use "../extension/mixin" as mi;

.l-body {
  background-color: var.$concrete;

  @include mi.mq(sp){
    overflow-x: hidden;
  }

  color: var.$mineShaft;

  &.is-fixed .l-wrapper {
    @include mi.mq(){
      overflow-y: hidden;
      max-height: 100%;
      padding-top: 134px;
    }
  }

  a {
    text-decoration: none;
    color: var.$mineShaft;
  }
}

.l-wrapper {
  @include mi.mq(){
    min-width: calc((1024 + (20 * 2)) * 1px);
    width: 100%;
    overflow-x: hidden;
  }

  @include mi.mq(sp){
    max-width: 100vw;
    overflow-x: hidden;
  }
}

.l-main {
  position: relative;

  @include mi.mq(sp){
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 50px;
  }

  @include mi.mq(){
    padding-bottom: 80px;
  }
}

.l-aside {
  background: rgba(var.$manda_green, 80%);
}