@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-hansel {
  //background: var.$concrete;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display:none;
  }

  &__body {
    display: flex;
    margin: auto;
    flex-wrap: nowrap;

    @include mi.mq(){
      width: 1024px;
    }

    @include mi.mq(sp){
      padding: 0 0 0 24px;
    }

    &::after {
      content: "";
      width: 24px;
      height: 36px;
      flex-shrink: 0;
    }
  }

  &__item {
    + .p-hansel__item {
      margin-left: 8px;

      &::before {
        content: "";
        width: 16px;
        height: 16px;
        margin-right: 8px;
        background: {
          image: url("../img/icn/icn_arrow_s_r_gray.svg");
          position: center;
          repeat: no-repeat;
        }
      }
    }

    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;

    @include mi.mq(sp){
      height: 36px;
    }

    @include mi.mq(){
      height: 52px;
    }

    a, mark {
      color: var.$doveGray;
      font-weight: bold;
      @include mi.fs(14);
    }
  }
}