@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.c-btn {
  &.-cmn {
    width: 327px;
    height: 48px;
    display: inline-flex;
    background: var.$manda_green;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: white;
    @include mi.fs(16);
    line-height: 1.5;
    font-weight: bold;

    &.-line {
      border: 1px solid var.$darkGray;
      background: white;
      color: var.$manda_green;
    }

    &.-thin {
      height: 40px;
      border-radius: 4px;
    }
  }

  &.-cart {
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: white;
    color: var.$manda_green;
    font-weight: bold;
    @include mi.fs(12);
    border-radius: 4px;
    padding: 4px;

    &::before {
      content: "";
      width: 24px;
      height: 24px;
      background: {
        image: url(../img/icn/icn_header_cart.svg);
        size: 24px;
        repeat: no-repeat;
      }
    }
  }

  &.-fav {
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 4px;
    background: {
      image: url(../img/icn/icn_fav.svg);
      repeat: no-repeat;
      position: center;
    }

    &[data-fav-status="true"] {
      border: 2px solid var.$greenHaze;
    }
  }

  &_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include mi.mq(sp){
      flex-direction: column;
      align-items: center;
    }

    .c-col {
      + .c-col {
        @include mi.mq(sp){
          margin-top: 16px;
        }

        @include mi.mq(){
          margin-left: 16px;
        }
      }
    }
  }
}