@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.c-heading {
  &.-home {
    + * {
      margin-top: 24px;
    }

    color: var.$manda_green;
    font-weight: bold;

    @include mi.mq(sp){
      @include mi.fs(20);
      line-height: 1.2;
    }

    @include mi.mq(){
      @include mi.fs(24);
    }
  }
}