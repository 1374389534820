@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.c-input_text {
  height: 48px;
  width: 100%;

  input {
    height: 100%;
    width: 100%;
    border: 2px solid var.$concrete;
    border-radius: 8px;
    padding-left: 56px;
    background-color: white;

    &::placeholder {
      color: var.$darkGray;
      opacity: 1;
    }
  }
}

.c-input_search {
  @extend .c-input_text;

  input {
    background: {
      image: url(../img/icn/icn_form_search.svg);
      repeat: no-repeat;
      position: 10px center;
    }

    &::placeholder {
      color: var.$darkGray;
      opacity: 1;
    }
  }
}