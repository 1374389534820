@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-product_list {
  + {
    .p-banner {
      @include mi.mq(sp){
        margin-top: 48px;
      }

      @include mi.mq(){
        margin-top: 64px;
      }
    }
  }

  .c-container {
    @include mi.mq() {
      width: 1024px;
      margin: auto;
    }

    @include mi.mq(sp){
      margin-right: -24px;
      margin-left: -24px;
    }
  }

  .c-heading {
    background: rgba(var.$manda_green, 80%);
    color: white;
    padding: 10px 24px;
    @include mi.fs(18);
    font-weight: bold;
    line-height: 1.5;
  }

  &__body {
    @include mi.mq(){
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    display: flex;
    flex-wrap: wrap;

    @include mi.mq(sp){
      border-top: 2px solid rgba(var.$manda_green, 20%);
      padding: 12px 8px;
    }

    @include mi.mq(){
      width: 50%;
      padding: 24px;
      position: relative;

      // 縦線
      &:nth-child(odd) {
        &::after {
          content: "";
          position: absolute;
          width: 2px;
          top: 24px;
          right: -1px;
          bottom: 24px;
          margin: auto;
          background: rgba(var.$manda_green, 20%);
        }
      }

      // 横線
      &:nth-child(even){
        + :nth-child(odd) {
          &::before {
            content: "";
            position: absolute;
            width: 1020px;
            height: 2px;
            background: rgba(var.$manda_green, 20%);
            top: -1px;
            left: 2px;
          }
        }
      }
    }

    .c-img {
      width: 148px;
      margin-right: 8px;
      margin-bottom: 8px;

      img {
        border-radius: 8px;
        border: 2px solid white;
      }
    }

    .c-desc {
      @include mi.mq(sp){
        margin-bottom: 4px;
        width: calc(100% - 148px - 8px);
      }

      @include mi.mq(){
        margin-bottom: 8px;
        flex: 1;
      }
    }

    .c-name {
      @include mi.fs(16);
      line-height: 1.5;
      margin-bottom: 8px;
    }

    .c-price {
      @include mi.fs(24);
      line-height: 1.66666667;
    }

    .c-btn_wrapper {
      width: 100%;

      @include mi.mq(sp){
        flex-direction: row;
        align-items: center;
      }

      span {
        + span {
          margin-left: 8px;
        }

        &:last-child {
          flex: 1;
        }
      }

      .c-btn.-cart {
        width: 100px;
      }

      .c-btn.-cmn {
        width: 100%;
        background: rgba(var.$manda_green, 60%);
      }
    }
  }

  &__type {
    display: flex;
    margin-bottom: 8px;

    @include mi.mq(sp){
      flex-wrap: wrap;
    }

    .c-type {
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }

      b {
        border: 2px solid;
        border-radius: 4px;
        white-space: nowrap;
        padding: 4px 14px;
        @include mi.fs(14);
        font-weight: bold;
        background: white;
        color: var.$manda_gold;
        height: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &.-trial {
        margin-bottom: 8px;

        @include mi.mq(sp){
          width: 100%;
        }

        b {
          color: var.$guardsmanRed;
        }
      }
    }
  }
}