$breakpoints: (
  'pc': 'screen and (min-width: 737px)',
  'sp': 'screen and (max-width: 736px)',
) !default;

// *** color ***
$guardsmanRed : #B50303;
$mineShaft : #3C3C3C;
$doveGray : #707070;
$darkGray: #ADADAD;
$concrete : #F2F2F2;
$manda_gold : #9E8949;
$greenHaze : #009F48;
$manda_green : #00612C;
$nepal : #98A6B5;