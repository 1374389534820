html {
  font-size: 112.5%;
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  font-family: 'Noto Sans JP', sans-serif;
}

html body * {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-weight: 400;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  border: 0;
  list-style: none;
  -webkit-text-size-adjust: 100%;
  text-decoration: none;
  line-height: 1;
  font-style: normal;

  *, *::before, *::after {
    appearance: none;
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    text-decoration: inherit;
    line-height: inherit;
    box-sizing: inherit;
    list-style: inherit;
    text-decoration: none;
    border: none;
    outline: none;
  }

  img {
    vertical-align: bottom;
  }
}