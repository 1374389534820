@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-product_detail {
  > .c-container {
    @include mi.mq() {
      width: 1024px;
      margin: auto;
      display: flex;
    }
  }

  &__slider {
    width: 512px;
    margin-right: 24px;
  }

  &__desc {
    flex: 1;
  }

  &__type {
    display: flex;
    margin-bottom: 8px;

    @include mi.mq(sp) {
      flex-wrap: wrap;
    }

    .c-type {
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }

      b {
        border: 2px solid;
        border-radius: 4px;
        white-space: nowrap;
        padding: 4px 14px;
        @include mi.fs(14);
        font-weight: bold;
        background: white;
        color: var.$manda_gold;
        height: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &.-trial {
        margin-bottom: 8px;

        @include mi.mq(sp) {
          width: 100%;
        }

        b {
          color: var.$guardsmanRed;
        }
      }
    }
  }

  .c-name {
    p {
      @include mi.fs(24);
      line-height: 1.5;
      font-weight: bold;
    }

    .c-capacity {
      display: block;
      @include mi.fs(14);
      font-weight: normal;
    }
  }

  .c-see_review {
    margin-top: 24px;

    .c-link {
      font-weight: bold;
      @include mi.fs(16);
      line-height: 1.5;
      color: #9E8949;

      &::after {
        content: "";
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        background: {
          image: url("../img/icn/icn_arrow_s_d_gold.svg");
          repeat: no-repeat;
          position: center;
        }
      }
    }
  }

  &__feature {
    margin-top: 24px;
    height: 155px;
    overflow: hidden;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 125px;
      background: linear-gradient(to top, #f2f2f2 0%, rgba(242, 242, 242, 0) 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      transition: .2s;
      z-index: 1;
    }

    &[data-sneak-peak-status="true"] {
      &::after {
        opacity: 0;
        pointer-events: none;
      }
    }

    p {
      line-height: 1.77777778;
    }

    .c-switch {
      text-align: center;
      position: absolute;
      z-index: 100;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;

      button {
        @include mi.fs(14);
      }
    }
  }

  &__select_type {
    margin-top: 30px;

    .c-btn {
      width: 100%;
      height: 88px;
      display: flex;
      background: white;
      flex-direction: column;
      border-radius: 8px;
      justify-content: flex-end;
      padding: 15px 18px;
      color: var.$manda_green;
      font-weight: bold;
      line-height: 1.25;
      position: relative;

      small {
        @include mi.fs(16);
      }

      b {
        @include mi.fs(24);
      }

      .c-img {
        position: absolute;
        right: 18px;
        bottom: 0;
      }
    }
  }

  &__purchase_plan {
    margin-top: 16px;

    li {
      + li {
        margin-top: 8px;
      }
    }

    label {
      display: block;
      background: white;
      border: 4px solid var.$darkGray;
      border-radius: 8px;
      padding: 18px 12px 12px 48px;
      position: relative;

      &::before {
        content: "";
        width: 24px;
        height: 24px;
        border-radius: 24px;
        border: 2px solid var.$doveGray;
        position: absolute;
        top: 26px;
        left: 12px;
        background: white;
        box-shadow: 0 0 0 3px white inset;
      }
    }

    input[type="radio"] {
      @include mi.invisible;

      &:checked {
        + label {
          border-color: var.$manda_green;

          &::before {
            border-color: var.$manda_green;
            background: var.$manda_green;
          }
        }
      }
    }

    .c-plan_name {
      @include mi.fs(24);
      line-height: 1.66666667;
      font-weight: bold;
      align-items: center;
      display: flex;
      justify-content: space-between;

      small {
        @include mi.fs(18);
        font-weight: normal;
      }
    }

    .c-price {
      + * {
        margin-top: 5px;
      }

      margin-top: 12px;
      display: block;
      @include mi.fs(28);
      line-height: 1.42857143;
    }

    .c-tax_type {
      @include mi.fs(12);
      margin-left: 8px;
    }

    .c-regular_price {
      @include mi.fs(12);

      .c-num {
        @include mi.fs(16);
        margin-left: 8px;
      }

      s {
        text-decoration: line-through;
      }
    }
  }
}