@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-carousel {
  + {
    .p-carousel {
      @include mi.mq(){
        margin-top: 80px;
      }

      @include mi.mq(sp){
        margin-top: 48px;
      }
    }
  }

  .c-container {
    @include mi.mq() {
      width: 1024px;
      margin: auto;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 832px;
        width: calc(100vw - 1024px + 192px);
        background: red;
        top: -20px;
        bottom: -20px;
        background: linear-gradient(to right, rgba(var.$concrete, 0) 0, var.$concrete 320px);
      }
    }
  }

  .c-list {
    display: flex;
    position: relative;
    //left: -50vw;

    li {
      flex-shrink: 0;
    }
  }

  .c-btn {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 100%;
    z-index: 2;
    cursor: pointer;
    background: {
      color: var.$manda_green;
      image: url(../img/icn/icn_arrow_s_r_white.svg);
      repeat: no-repeat;
      position: 16px center;
      size: 36px;
    }

    &.-forward {
      right: 0
    }

    &.-back {
      transform: rotate(180deg);
      left: 0;
    }
  }

  &.-ranking {
    li {
      position: relative;
      counter-increment: ranking;

      @include mi.mq(sp){
        width: 144px;
        margin-right: 8px;
      }

      @include mi.mq() {
        width: 196px;
        margin-right: 16px;
      }

      &:nth-child(-n+3) {
        .c-ribbon {
          &::before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            left: 0;
            top: calc(100% - 1px);
            transform-origin: top;
            transform: scale(100%, 70%);

            @include mi.mq(){
              border-width: 18px 18px 0 0;
            }

            @include mi.mq(sp){
              border-width: 13px 13px 0 0;
            }
          }

          &::after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent var.$darkGray transparent transparent;
            position: absolute;
            right: 0;
            top: calc(100% - 1px);
            transform-origin: top;
            transform: scale(100%, 70%);

            @include mi.mq(){
              border-width: 0 18px 18px 0;
            }

            @include mi.mq(sp){
              border-width: 0 13px 13px 0;
            }
          }
        }
      }

      &:nth-child(1) {
        .c-ribbon {
          background: #9E8949;

          &::before {
            border-color: #9E8949 transparent transparent transparent;
          }

          &::after {
            border-color: transparent #9E8949 transparent transparent;
          }
        }
      }

      &:nth-child(2) {
        .c-ribbon {
          background: #7B90A8;

          &::before {
            border-color: #7B90A8 transparent transparent transparent;
          }

          &::after {
            border-color: transparent #7B90A8 transparent transparent;
          }
        }
      }

      &:nth-child(3) {
        .c-ribbon {
          background: #9B937A;

          &::before {
            border-color: #9B937A transparent transparent transparent;
          }

          &::after {
            border-color: transparent #9B937A transparent transparent;
          }
        }
      }
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      background: white;
      border-radius: 8px;
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);

      &::before {
        content: counter(ranking);
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        color: white;
        line-height: 1.25;

        @include mi.mq(){
          width: 35px;
          height: 44px;
          @include mi.fs(33);
          left: 16px;
        }

        @include mi.mq(sp){
          width: 25px;
          height: 32px;
          @include mi.fs(24);
          left: 10px;
        }
      }
    }

    .c-ribbon {
      background: var.$darkGray;
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      color: white;
      line-height: 1.25;

      @include mi.mq(){
        width: 35px;
        height: 44px;
        left: 16px;
      }

      @include mi.mq(sp){
        width: 25px;
        height: 32px;
        left: 10px;
      }
    }
  }

  &.-recommend {
    li {
      position: relative;

      @include mi.mq(sp){
        width: 180px;
        margin-right: 8px;
      }

      @include mi.mq() {
        width: 200px;
        margin-right: 16px;
      }
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      background: white;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.16);

    }

    .c-figure {
      padding: 8px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .c-img {
      overflow: hidden;
      margin: -8px -8px 0;

      img {
        display: block;
        outline: 4px solid white;
        outline-offset: -4px;
        border-radius: 8px;
      }
    }

    figcaption {
      font-weight: bold;

      @include mi.mq(sp){
        @include mi.fs(14);
        line-height: 1.71428571;
      }

      @include mi.mq(){
        @include mi.fs(16);
        line-height: 1.5;
        margin-bottom: 24px;
      }
    }

    .c-price {
      @include mi.fs(20);
      line-height: 1.2;
      margin-top: auto;
    }
  }
}