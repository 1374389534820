@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.c-link {
  &.-img {
    img {
      transition: .2s;
    }

    &:hover {
      img {
        opacity: .7;
      }
    }
  }
}