@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-header {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  &__container {
    position: relative;
    height: 80px;
    display: flex;

    @include mi.mq(sp){
      padding: 14px 16px;
    }

    @include mi.mq(){
      padding: 16px 36px 0;
      max-width: 1280px;
      min-width: 1024px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__welcome {
    @include mi.mq(sp){
      display: none;
    }

    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: var.$manda_green;
    @include mi.fs(14);
    line-height: 1.71428571;
    font-weight: bold;
    margin-right: 36px;

    .c-user_name {
      @include mi.fs(18);
      line-height: 1.33333333;
      margin-right: 8px;
    }
  }

  &__symbol {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include mi.mq(sp){
      margin-left: 7px;
    }

    @include mi.mq(){
      margin-left: 16px;
      margin-right: 52px;

      .c-shoulder {
        img {
          width: 96px;
        }
      }

      .c-symbol {
        img {
          width: 86px;
        }
      }
    }
  }

  &__search {
    margin-left: auto;
    flex: 1;

    @include mi.mq(sp){
      display: none;
    }
  }
}