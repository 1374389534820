@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-topics {
  * + & {
    @include mi.mq(sp){
      margin-top: 48px;
    }
    @include mi.mq(){
      margin-top: 80px;
    }
  }

  .c-container {
    @include mi.mq() {
      width: 1024px;
      margin: auto;
      position: relative;
    }
  }

  .c-list {
    + {
      .c-list {
        display: none;
        border-top: none;
      }

      .c-read_more {
        margin-top: 24px;
      }
    }

    border-top: 1px solid var.$darkGray;

    .c-scope {
      border-bottom: 1px solid var.$darkGray;
      padding: 16px 0;
    }

    dt {
      + dd {
        margin-top: 8px;
      }
      @include mi.fs(16);
      line-height: 1.5;
      font-weight: bold;

      .c-date {
        margin-right: 8px;
        color: var.$darkGray;
      }

      .c-important {
        color: var.$guardsmanRed;
      }
    }

    dd {
      line-height: 1.5;

      @include mi.mq(){
        @include mi.fs(20);
      }

      @include mi.mq(sp){
        @include mi.fs(18);
      }
    }
  }

  .c-read_more {
    text-align: center;
    overflow: hidden;
    transition: .2s;

    .c-btn {
      font-weight: bold;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      &[data-accordion-status="true"] {
        height: 0;
      }
    }
  }
}