@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-aside {
  // *** footer social ***
  &__social {
    @include mi.mq() {
      width: 1024px;
      margin: auto;
    }

    ul {
      display: flex;
      justify-content: center;
      padding: 50px 0;
    }

    li + li {
      margin-left: 40px;
    }
  }

  // *** footer sitemap ***
  &__sitemap {
    * {
      color: white;
    }

    margin-top: 18px;

    @include mi.mq() {
      width: 1024px;
      margin: auto;
      display: flex;
      align-items: flex-start;
      padding-bottom: 10px;
    }

    @include mi.mq(sp) {
      padding: 0 24px;
    }

    .c-caption {
      width: 100%;
      @include mi.fs(18);
      font-weight: bold;
      line-height: 1.33333333;
      margin-bottom: 24px;

      &.-has_children {
        + {
          .c-list {
            @include mi.mq() {
              display: block !important;
              height: auto !important;
            }
          }

          .c-group {
            @include mi.mq(){
              display: flex !important;
              height: auto !important;
            }
          }
        }

        @include mi.mq(sp) {
          position: relative;
          transition: .3s;

          &::before, &::after {
            content: "";
            width: 18px;
            height: 2px;
            background: white;
            position: absolute;
            top: 0;
            right: 3px;
            bottom: 0;
            margin: auto;
            transition: .2s;
          }

          &::after {
            transform: rotate(90deg);
          }

          &[data-accordion-status="false"] {
            margin-bottom: 0;
          }

          &[data-accordion-status="true"] {
            &::before, &::after {
              background: var.$darkGray;
            }

            &::after {
              width: 0;
              right: 11px;
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    .c-group {
      + .c-group {
        @include mi.mq(sp) {
          margin-top: 25px;
        }
      }

      &.-product {
        @include mi.mq() {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          width: calc((1024 - 20 * 2) / 3 * 2px + 20px);
          margin-right: 20px;
        }

        .c-scope {
          + .c-scope {
            margin-top: 24px;
          }
        }
      }

      &.-product_container {
        @include mi.mq(){
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
        }
      }

      &.-before {
        @include mi.mq() {
          width: calc((100% - 20px) / 2);
          margin-right: 20px;
        }
      }

      &.-after {
        flex: 1;
      }

      &.-other {
        flex: 1;
      }

      dt {
        @include mi.fs(14);
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 12px;
      }

      dd {
        + dd {
          margin-top: 12px;
        }
      }

      .c-list {
        &.-lv1 {
          @include mi.mq(sp) {
            border-bottom: 1px solid var.$darkGray;
          }

          > li {
            + li {
              @include mi.mq() {
                margin-top: 24px;
                padding-top: 24px;
                border-top: 1px solid var.$darkGray;
              }
            }

            @include mi.mq(sp) {
              padding: 25px 0;
              border-top: 1px solid var.$darkGray;
            }
          }
        }

        &.-lv2 {
          li {
            + li {
              margin-top: 15px;
            }
          }
        }
      }

      .c-link {
        display: flex;
        font-weight: bold;
        color: white;
        background: {
          image: url("../img/icn/icn_arrow_r_white.svg");
          repeat: no-repeat;
          position: right center;
        }
        line-height: 1.44444444;

        &.-bar {
          align-items: center;
          font-weight: normal;
          background: {
            color: rgba(white, 10%);
            position: calc(100% - 8px) center;
          }
          min-height: 48px;
          padding: 10px;
          transition: .2s;

          @include mi.mq() {
            &:hover {
              background: {
                color: rgba(white, 20%);
              }
              color: white;
            }
          }
        }
      }
    }
  }
}