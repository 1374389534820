@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-guide {
  * + & {
    @include mi.mq(sp){
      margin-top: 48px;
    }
  }

  .c-container {
    @include mi.mq() {
      width: 1024px;
      margin: auto;
      position: relative;
    }
  }

  &.-home {
    @include mi.mq(){
      margin-top: 80px;
    }

    .c-container {
      background: white;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      border-radius: 16px;

      @include mi.mq(){
        padding: 32px 0 40px;
      }

      @include mi.mq(sp){
        padding: 36px 24px 16px;
        margin-right: -16px;
        margin-left: -16px;
      }
    }

    .c-heading {
      width: 100%;
      text-align: center;

      @include mi.mq(sp){
        margin-bottom: 36px;
      }

      @include mi.mq(){
        margin-bottom: 28px;
      }
    }

    .c-figure {
      + .c-figure {
        @include mi.mq(){
          border-left: 1px solid var.$darkGray;
        }
      }
      margin-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @include mi.mq(sp){
        border-top: 1px solid var.$darkGray;
        padding: 16px 0;
        position: relative;
        padding-left: 80px;
      }

      @include mi.mq(){
        width: calc(100% / 3);
        padding: 20px 24px 19px 16px;
      }

      .c-icn {
        @include mi.mq(sp){
          position: absolute;
          top: 16px;
          left: 0;
          width: 64px;

          img {
            width: 100%;
          }
        }

        @include mi.mq(){
          text-align: center;
          margin-bottom: 35px;
        }

        img {
          width: 120px;
        }
      }

      figcaption {
        font-weight: bold;
        color: var.$manda_green;
        @include mi.fs(20);
        margin-bottom: 8px;
      }

      p {
        + {
          .c-read_more {
            margin-top: 8px;
          }
        }

        @include mi.fs(14);
        line-height: 1.71428571;
      }

      .c-read_more {
        font-weight: bold;

        .c-link {
          color: var.$manda_green;
        }
      }
    }

    .c-btn_wrapper {
      margin-top: 24px;
    }
  }
}