@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.c-grid {
  display: flex;
  flex-wrap: wrap;

  &.-nowrap {
    flex-wrap: nowrap;
  }

  &.-center {
    justify-content: center;
  }

  @include mi.mq(){
    // column
    .c-col {
      &[data-col*="pc:1"] {
        width: 100%;
      }

      &[data-col*="pc:2-1"] {
        width: calc(100% / 2 * 1);
      }

      &[data-col*="pc:3-1"] {
        width: calc(100% / 3 * 1);
      }

      &[data-col*="pc:3-2"] {
        width: calc(100% / 3 * 2);
      }

      &[data-col*="pc:4-1"] {
        width: calc(100% / 4 * 1);
      }

      &[data-col*="pc:4-3"] {
        width: calc(100% / 4 * 3);
      }

      // order
      @for $value from 1 through 10 {
        &[data-order*="pc:#{$value}"] {
          order: #{$value};
        }
      }
    }

    // row gap
    @for $value from 1 through 4 {
      &[data-gap-row*="pc:#{$value * 8}"] {
        margin-bottom: -#{$value * 8}px;

        > .c-col {
          margin-bottom: #{$value * 8}px;
        }
      }
    }

    // col gap
    @for $value from 1 through 4 {
      &[data-gap-col*="pc:#{$value * 8}"] {
        margin-right: calc(-#{$value * 8}px);

        > .c-col {
          margin-right: #{$value * 8}px;

          &[data-col*="pc:1"] {
            width: calc(100% - #{$value * 8}px);
          }

          &[data-col*="pc:2-1"] {
            width: calc(100% / 2 * 1 - #{$value * 8}px);
          }

          &[data-col*="pc:3-1"] {
            width: calc(100% / 3 * 1 - #{$value * 8}px);
          }

          &[data-col*="pc:3-2"] {
            width: calc(100% / 3 * 2 - #{$value * 8}px);
          }

          &[data-col*="pc:4-1"] {
            width: calc(100% / 4 * 1 - #{$value * 8}px);
          }

          &[data-col*="pc:4-3"] {
            width: calc(100% / 4 * 3 - #{$value * 8}px);
          }

          &[data-col*="pc:5-1"] {
            width: calc(100% / 5 * 1 - #{$value * 8}px);
          }
        }
      }
    }
  }

  @include mi.mq(sp){
    .c-col {
      &[data-col*="sp:1"] {
        width: 100%;
      }

      &[data-col*="sp:2-1"] {
        width: calc(100% / 2 * 1);
      }

      &[data-col*="sp:3-1"] {
        width: calc(100% / 3 * 1);
      }

      // order
      @for $value from 1 through 10 {
        &[data-order*="sp:#{$value}"] {
          order: #{$value};
        }
      }
    }

    // row gap
    @for $value from 1 through 4 {
      &[data-gap-row*="sp:#{$value * 8}"] {
        margin-bottom: -#{$value * 8}px;

        > .c-col {
          margin-bottom: #{$value * 8}px;
        }
      }
    }

    // col gap
    @for $value from 1 through 4 {
      &[data-gap-col*="sp:#{$value * 8}"] {
        margin-right: calc(-#{$value * 8}px);

        > .c-col {
          margin-right: #{$value * 8}px;

          &[data-col*="sp:1"] {
            width: calc(100% - #{$value * 8}px);
          }

          &[data-col*="sp:2-1"] {
            width: calc(100% / 2 * 1 - #{$value * 8}px);
          }
        }
      }
    }
  }
}
