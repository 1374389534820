@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-banner {
  &.-home {
    + * {
      @include mi.mq(sp){
        margin-top: 24px;
      }

      @include mi.mq(){
        margin-top: 80px;
      }
    }

    .c-container {
      @include mi.mq(){
        width: 1024px;
        margin: auto;
      }
    }

    a img {
      border-radius: 8px;
    }
  }

  &.-standalone {
    + {
      .p-carousel {
        @include mi.mq(sp){
          margin-top: 56px;
        }

        @include mi.mq(){
          margin-top: 80px;
        }
      }
    }
    text-align: center;

    @include mi.mq(){
      margin: 16px 0;
    }

    @include mi.mq(sp){
      margin: 16px -8px;
    }

    img {
      border-radius: 8px;
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.06);


      @include mi.mq(sp){
        max-width: 100%;
      }
    }
  }
}