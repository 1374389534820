@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-btt {
  a {
    position: fixed;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 100%;
    flex-direction: column;
    background: {
      color: white;
    }
    color: var.$manda_green;
    font-weight: bold;

    @include mi.mq(sp){
      width: 65px;
      height: 64px;
      right: 16px;
      bottom: 16px;
      @include mi.fs(11);
      padding-top: 8px;
    }

    @include mi.mq(){
      width: 80px;
      height: 80px;
      right: 24px;
      bottom: 24px;
      @include mi.fs(13);
      padding-top: 12px;
    }

    &::before {
      content: "";
      background: {
        image: url(../img/icn/icn_arrow_btt.svg);
        repeat: no-repeat;
        position: center;
      }

      @include mi.mq(sp){
        width: 24px;
        height: 24px;
        background-size: 24px;
      }

      @include mi.mq(){
        width: 30px;
        height: 30px;
      }
    }
  }
}