@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-global_nav {
  position: fixed;
  left: 0;
  right: 0;

  @include mi.mq(sp) {
    pointer-events: none;
    transition: .2s;
    top: 80px;
    bottom: 0;
  }

  @include mi.mq() {
    margin-top: 80px;
    height: 54px;
    background: white;
    padding-top: 8px;
    top: 0;
  }

  // スクロールステージ
  &__container {
    @include mi.mq(sp) {
      width: 316px;
      background: var.$concrete;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      transition: .2s;
      transform: translateX(100%);
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  // スクロール本体
  &__body {
    @include mi.mq(sp) {
      padding: 24px 24px 24px 40px;
    }

    @include mi.mq(){
      width: 1024px;
      margin: auto;
    }
  }

  // 背景
  [data-gnav-status="true"] & {
    @include mi.mq(sp) {
      background: rgba(black, 50%);
      pointer-events: auto;

      .p-global_nav__container {
        transform: translateX(0);
      }
    }
  }

  &__login {
    @include mi.mq(sp) {
      height: 50px;
      display: flex;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    @include mi.mq() {
      display: none;
    }

    a {
      @include mi.mq(sp) {
        font-weight: bold;
        display: block;
        width: 100%;
        position: relative;
      }

      &::after {
        @include mi.mq(sp) {
          content: "";
          background-image: url(../img/icn/icn_arrow_r.svg);
          background-position: center;
          background-repeat: no-repeat;
          bottom: 0;
          height: 24px;
          margin: auto;
          position: absolute;
          right: 0;
          top: 0;
          width: 24px;
        }
      }
    }
  }

  // ようこそ！
  &__welcome {
    @include mi.mq(sp) {
      color: var.$manda_green;
      font-weight: bold;
      @include mi.fs(14);
      line-height: 1.71428571;
      height: 50px;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
    }

    @include mi.mq() {
      display: none;
    }

    .c-user_name {
      @include mi.mq(sp) {
        @include mi.fs(18);
        line-height: 1.33333333;
        margin-right: 10px;
      }

      @include mi.mq() {
        display: none;
      }
    }
  }

  // contents
  &__contents {
    @include mi.mq() {
      display: flex;
      justify-content: center;
      max-width: 1024px;
    }

    .c-menu_item {
      &.-lv1 {
        + {
          .c-menu_item {
            @include mi.mq(sp) {
              margin-top: 48px;
            }

            @include mi.mq() {
              margin-left: 24px;
            }
          }
        }
      }

      &.-has_children,
      &.-lv2 {
        + {
          .-has_children,
          .-lv2 {
            @include mi.mq(sp) {
              margin-top: 24px;
            }
          }
        }

        @include mi.mq() {
          margin: 0 8px;
          width: 100%;
          max-width: 200px;
          text-align: center;
        }

        ul {
          @include mi.mq(sp) {
            display: none;
          }

          &:first-child {
            @include mi.mq() {
              margin-top: calc(18px + 8px);
            }
          }
        }

        li {
          + li {
            @include mi.mq(sp) {
              margin-top: 12px;
            }

            @include mi.mq() {
              margin-top: 8px;
            }
          }

          a {
            background: white;
            display: flex;
            align-items: center;

            @include mi.mq(sp) {
              height: 48px;
              padding: 10px 16px;
            }

            @include mi.mq() {
              height: 36px;
              padding: 4px 16px;
              @include mi.fs(14);
              justify-content: center;
            }
          }
        }
      }

      .c-link {
        + ul {
          @include mi.mq(sp) {
            margin-top: 10px;
          }
        }

        @include mi.mq(sp) {
          @include mi.fs(18);
          font-weight: bold;
          height: 30px;
          display: flex;
          position: relative;
          color: var.$mineShaft;
          transition: .2s;
          align-items: center;
          margin-bottom: 12px;
        }

        &::after {
          @include mi.mq(sp) {
            content: "";
            width: 24px;
            height: 24px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background: {
              image: url("../img/icn/icn_arrow_r.svg");
              repeat: no-repeat;
              position: center;
            }
          }
        }

        .c-icn {
          @include mi.mq(sp) {
            vertical-align: middle;
            margin-right: 8px;
          }
        }
      }

      // logout
      &.-logout {
        @include mi.mq(sp) {
          border-top: 1px solid var.$darkGray;
          margin-top: 42px;
          padding-top: 24px;
        }

        .c-link {
          @include mi.mq(sp) {
            color: var.$doveGray;

            &::after {
              content: "";
              width: 24px;
              height: 24px;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              background: {
                image: url("../img/icn/icn_arrow_r_lightgray.svg");
                repeat: no-repeat;
                position: center;
              }
            }
          }

          @include mi.mq() {
            color: var.$doveGray;
            font-weight: bold;
            display: inline-flex;
            align-items: center;
            height: 24px;
            @include mi.fs(16);
          }
        }
      }
    }

    .c-category {
      @include mi.mq(sp) {
        display: flex;
        color: var.$manda_green;
        font-weight: bold;
        @include mi.fs(20);
        margin-left: -10px;
        margin-right: -24px;
        height: 48px;
        align-items: center;
        margin-bottom: 24px;
        pointer-events: none;
        width: calc(100% + 24px + 8px);

        &::before {
          content: "";
          width: 36px;
          height: 36px;
          margin-right: 8px;
          background: {
            size: 36px;
            repeat: no-repeat;
            position: center;
          }
        }

        // 万田酵素について
        &.-about {
          &::before {
            background: {
              image: url(../img/icn/icn_gn_about_sp.svg);
            }
          }
        }

        // 商品を探す
        &.-search {
          &::before {
            background: {
              image: url(../img/icn/icn_gn_search_green.svg);
            }
          }
        }

        // お得な定期便
        &.-subscription {
          &::before {
            background: {
              image: url(../img/icn/icn_gn_calendar_green.svg);
            }
          }
        }

        // サポート・ガイド
        &.-guide {
          &::before {
            background: {
              image: url(../img/icn/icn_gn_gude_green.svg);
            }
          }
        }

        // お問合せ
        &.-inquiry {
          &::before {
            background: {
              image: url(../img/icn/icn_gn_mail_green.svg);
            }
          }
        }
      }

      @include mi.mq() {
        color: var.$doveGray;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
        height: 24px;
        @include mi.fs(16);
        white-space: nowrap;

        // accordion
        &.fn-accordion {
          &::after {
            content: "";
            width: 16px;
            height: 16px;
            margin-left: 8px;
            background: {
              image: url(../img/icn/icn_arrow_s_d_gray.svg);
              repeat: no-repeat;
              position: center;
            }
          }
        }

        // 開閉
        &[data-accordion-status="true"] {
          color: var.$manda_green;
        }

        // 商品を探す
        &.-search {
          &::before {
            content: "";
            width: 24px;
            height: 24px;
            margin-right: 4px;
            background: {
              image: url(../img/icn/icn_gn_search_gray.svg);
              repeat: no-repeat;
              position: center;
            }
          }

          &[data-accordion-status="true"] {
            &::before {
              background: {
                image: url(../img/icn/icn_gn_search_green.svg);
              }
            }
          }
        }

        // お得な定期便
        &.-subscription {
          &::before {
            content: "";
            width: 24px;
            height: 24px;
            margin-right: 4px;
            background: {
              image: url(../img/icn/icn_gn_calendar_gray.svg);
              repeat: no-repeat;
              position: center;
            }
          }
        }

        // サポート・ガイド
        &.-guide {
          &::before {
            content: "";
            width: 24px;
            height: 24px;
            margin-right: 4px;
            background: {
              image: url(../img/icn/icn_gn_gude_gray.svg);
              repeat: no-repeat;
              position: center;
            }
          }

          &[data-accordion-status="true"] {
            &::before {
              background: {
                image: url(../img/icn/icn_gn_gude_green.svg);
              }
            }
          }
        }

        // お問合せ
        &.-inquiry {
          &::before {
            content: "";
            width: 24px;
            height: 24px;
            margin-right: 4px;
            background: {
              image: url(../img/icn/icn_gn_mail_gray.svg);
              repeat: no-repeat;
              position: center;
            }
          }
        }
      }
    }

    .c-container {
      @include mi.mq(sp) {
        display: block !important;
        margin-left: 6px;
      }

      @include mi.mq() {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        background: var.$concrete;
        padding: 24px 16px;
        border-radius: 0 0 16px 16px;
      }

      > ul {
        @include mi.mq() {
          width: calc(1024px + 16px);
          margin: auto;
          display: flex;
        }
      }
    }

    // accordion trigger
    .c-sub_category {
      + ul {
        @include mi.mq(sp) {
          margin-top: 10px;
          display: none;
        }

        @include mi.mq() {
          display: block !important;
          margin-top: 8px;
        }
      }

      @include mi.mq(sp) {
        @include mi.fs(18);
        font-weight: bold;
        height: 30px;
        display: flex;
        position: relative;
        color: var.$mineShaft;
        transition: .2s;
        align-items: center;
        margin-bottom: 12px;
      }

      @include mi.mq() {
        pointer-events: none;
        @include mi.fs(12);
        line-height: 1.5;
        font-weight: bold;
        min-height: 18px;
        display: block;
      }

      &::before, &::after {
        @include mi.mq(sp) {
          content: "";
          width: 18px;
          height: 2px;
          background: var.$mineShaft;
          transition: .2s;
          position: absolute;
          top: 0;
          right: 2px;
          bottom: 0;
          margin: auto;
        }
      }

      &::after {
        @include mi.mq(sp) {
          transform: rotate(90deg);
        }
      }

      // 展開時
      &[data-accordion-status="true"] {
        @include mi.mq(sp) {
          color: var.$doveGray;
        }

        &::before {
          @include mi.mq(sp) {
            background: var.$doveGray;
          }
        }

        &::after {
          @include mi.mq(sp) {
            transform: rotate(90deg) scale(0);
            background: var.$doveGray;
          }
        }
      }
    }
  }
}