@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-sp_search {
  @include mi.mq(){
    display: none;
  }

  padding: 8px;
}

.p-home {
  &_bnr {
    @include mi.mq(sp){
      margin-right: -24px;
      margin-left: -24px;
      margin-bottom: 4px;

      img {
        width: 100%;
      }
    }

    @include mi.mq(){
      text-align: center;
      min-width: 1024px;
      background: var.$greenHaze;
      height: 80px;
      margin-bottom: 4px;

      img {
        height: 80px;
      }
    }
  }

  &_category_nav {
    + * {
      @include mi.mq(sp){
        margin-top: 24px;
      }

      @include mi.mq(){
        margin-top: 80px;
      }
    }

    .c-container {
      @include mi.mq(){
        width: 1024px;
        margin: auto;
      }
    }

    .c-list {
      display: flex;

      @include mi.mq(sp){
        margin-right: -9px;
        margin-bottom: -8px;
        flex-wrap: wrap;
      }

      @include mi.mq(){
        margin-right: -16px;
      }

      li {
        @include mi.mq(sp){
          margin-right: 9px;
          width: calc(100% / 2 - 9px);
          margin-bottom: 8px;
        }

        @include mi.mq(){
          margin-right: 16px;
          width: calc(100% / 6 - 16px);
        }
      }

      a {
        display: block;
        background: #fff;

        @include mi.mq(sp){
          border-radius: 8px;
        }

        @include mi.mq(){
          border-radius: 16px;
          box-shadow: 0 3px 18px rgba(0, 0, 0, 0.08);
        }
      }
    }

    .c-figure {
      display: flex;
      align-items: center;

      @include mi.mq(sp){
        padding: 6px 5px 6px 10px;
      }

      @include mi.mq(){
        height: 157px;
        padding: 18px;
        flex-direction: column;
        justify-content: space-between;
      }

      .c-img {
        @include mi.mq(sp){
          width: 53px;
          margin-right: 7px;
        }

        @include mi.mq(){
          width: 102px;
        }
      }

      figcaption {
        color: rgba(var.$manda_green, 80%);
        font-weight: bold;
        white-space: nowrap;

        @include mi.mq(sp){
          @include mi.fs(16);
        }
      }
    }
  }

  &_contact {
    background: white;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mi.mq(sp){
      margin: 48px -24px -50px;
    }

    @include mi.mq(){
      margin-top: 80px;
      margin-bottom: -80px;
    }

    .c-link {
      display: inline-flex;
      align-items: center;
      color: var.$greenHaze;
      font-weight: bold;
      @include mi.fs(24);

      &::before {
        content: "";
        width: 35px;
        height: 35px;
        display: block;
        margin-right: 18px;
        transform: translateY(2px);
        background: {
          image: url("../img/icn/icn_info.svg")
        }
      }

      &::after {
        content: "";
        width: 24px;
        height: 24px;
        margin-left: 16px;
        background: {
          image: url("../img/icn/icn_arrow_r_lightgreen.svg");
          repeat: no-repeat;
          position: center;
        }
      }
    }
  }
}