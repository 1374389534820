@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

/*
fn-accordion に -has_children がついていれば、直後の要素を dn する。
直下の要素の開閉で、PC だけ、あるいは SP だけの時は、個別に CSS で固定する。
*/

// *** accordion ***
.fn-accordion.-has_children {
  + * {
    display: none;
  }
}