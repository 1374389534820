@use "sass:map";
@use "sass:math";
@use "variables" as var;

// *** Media Queries ***
@mixin mq($breakpoint: pc) {
  @media #{map-get(var.$breakpoints, $breakpoint)} {
    @content;
  }
}

// font-size
@mixin fs($size: 10, $base: 18) {
  font-size: math.div($size, $base) * 1rem;
}

// *** 幅375px換算で px を変換
@function sp_size($value) {
  //@return $value / 375 * 100vw;
  $min_size : $value * 0.8533333333px;
  $max_size : $value * 1px;
  @return clamp(#{$min_size}, calc(#{$value} / 375 * 100vw), #{$max_size});
}

@mixin invisible() {
  position: fixed;
  top: -1px;
  left: 0;
  width: 0;
  transform: translate(-1px, -1px);
  height: 1px;
  padding: 1px 0 0 1px;
  visibility: hidden;
  overflow: hidden;
}