@use "../../extension/variables" as var;
@use "../../extension/mixin" as mi;

.p-main_nav {
  @include mi.mq(sp){
    margin-left: auto;
    margin-top: -7px;
    padding-top: 7px;
  }

  @include mi.mq(){
    margin-top: -2px;
    margin-left: 24px;
  }

  ul {
    display: flex;
  }

  li {
    + li {
      @include mi.mq(sp){
        margin-left: 10px;
      }

      @include mi.mq(){
        margin-left: 24px;
      }
    }

    display: flex;
    position: relative;

    @include mi.mq(sp){
      justify-content: center;
      align-items: flex-end;
    }

    &.c-search {
      @include mi.mq(){
        display: none;
      }
    }

    a, button {
      position: relative;
      display: inline-flex;

      @include mi.mq(sp){
        width: 50px;
        height: 51px;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
      }

      @include mi.mq(){
        padding-top: 8px;
        align-items: center;
      }
    }
  }

  .c-icn {
    @include mi.mq(sp){
      width: 36px;
      height: 36px;
    }

    @include mi.mq(){
      margin-right: 8px;
    }
  }

  .c-label {
    white-space: nowrap;
    color: var.$doveGray;
    font-weight: bold;

    @include mi.mq(sp){
      @include mi.fs(10);
      line-height: 1.5;
      height: 15px;
    }

    @include mi.mq(){
      @include mi.fs(16);
      color: var.$manda_green;
      text-align: left;
    }
  }

  .c-badge {
    position: absolute;
    background: #9E8949;
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    color: white;
    border-radius: 21px;
    @include mi.fs(14);
    font-weight: bold;

    @include mi.mq(sp){
      top: -7px;
      right: 0;
    }

    @include mi.mq(){
      top: 0;
      left: 22px;
    }
  }

  .c-hamburger {
    .c-icn {
      position: relative;
      height: 36px;
      width: 50px;

      &::before, &::after, .c-patty {
        content: "";
        width: 38px;
        height: 3px;
        background: var.$manda_green;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        transition: .2s;
      }

      &::before {
        transform: translateY(-7px);
      }

      &::after {
        transform: translateY(7px);
      }
    }

    .c-label {
      @include mi.mq(){
        width: 3.6rem;
      }
    }

    [data-gnav-status="true"] & {
      .c-icn {
        &::before {
          transform: translateY(0) rotate(45deg);
          background: var.$nepal;
        }

        &::after {
          transform: translateY(0) rotate(-45deg);
          background: var.$nepal
        }

        .c-patty {
          width: 0;
        }
      }
    }
  }
}