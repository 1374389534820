@use "../extension/variables" as var;
@use "../extension/mixin" as mi;

.l-header {
  @include mi.mq(sp){
    z-index: 100;
  }
  z-index: 3
}

.p-header,
.p-header__shortcuts {
  z-index: 100;
}

.l-main {
  z-index: 2;
}

.p-global_nav {
  position: relative;
  z-index: 99;
}

.p-fixed_bnr.-home {
  z-index: 1;
}

.p-btt a {
  z-index: 20;
}